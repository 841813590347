// Fontawesome fixes
i.fa, i.fas, i.far, i.fab, i.fal, i.fad {
  font-style: normal; // This will override the italic styling
  font-family: 'Font Awesome 6 Free', sans-serif; // This will ensure the Font Awesome font is used
}

// Header
.brand-image{
  max-width: 200px;
}

// Typography

body{
  --bs-body-font-size: 1.15rem;
}

.text-italic{
  font-style: italic;
}

.fw-extrabold {
    font-weight: 800 !important;
}

a{
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-bold;
  color: $primary;
}

// Social Icons
.social-icons {
  a {
    color: $white;
    .fa-stack {
      .fa-circle {
        color: $primary;
      }
    }
    &:hover {
      .fa-stack {
        .fa-circle {
          color: $secondary;
        }
      }
    }
  }
}

// Navbar
.navbar {
  .nav-item {
    .nav-link {
      --bs-nav-link-padding-y: 4px;
      font-weight: $font-weight-bold;
      color: $primary;
      &.active,
      &:hover {
        color: $secondary;
      }
    }
    &.active {
      .nav-link {
        color: var(--bs-secondary-dark);
        &:hover {
          color: $secondary;
        }
      }
    }
    > .dropdown-menu {
      padding: 0;
      border: none;
      border-radius: 0;
      .dropdown-item {
        padding: 0;
        &:hover {
          --bs-dropdown-link-hover-bg: var(--bs-secondary-light);
        }
        &.active,
        &:active {
          --bs-dropdown-link-active-bg: var(--bs-secondary);
        }
        .nav-link {
          color: $primary;
          font-weight: $font-weight-semibold;
          padding: 10px 10px;
          &:hover {
            color: $primary;
          }
          &.active{
            color: var(--bs-secondary-dark);
            &:hover {
              color: $primary;
            }
          }
        }
        // Targeting the grandchild dropdown-items
        > .dropdown-menu {
          border: 0;
          border-radius: 0;
          padding: 0;
          position: relative;
          .dropdown-item {
            .nav-link {
              padding-left: 25px; // Indent grandchild links slightly more than the child
              &:hover {
                color: $primary; // Apply hover color directly to grandchild link
                background-color: var(--bs-secondary-light); // Apply hover background color directly to grandchild link
              }
            }
          }
        }
      }
    }
  }
}

// Lists

ul.arrow-list {
  list-style: none; /* Remove default list styling */
  padding-left: 0; /* Remove default padding */
  li {
    position: relative; /* Allows absolute positioning of pseudo-elements relative to these items */
    padding-left: 20px; /* Make space for the arrow */
    &:before {
      content: '\f0da'; /* Arrow character */
      font: var(--fa-font-solid);
      position: absolute; /* Position absolutely within the li */
      left: 0; /* Align to the left */
      top: 4px; /* Align to the top */
      color: $secondary; /* Arrow color */
    }
  }
}

// Buttons
a,
button {
  &.btn {
    border-radius: 0;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;

    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }

    &.w-50 {
      width: 100% !important; // !important should generally be avoided unless absolutely necessary
      @include media-breakpoint-up(lg) {
        width: 50% !important; // This will apply only on large screens
      }
    }

    &-lg, &-group-lg > .btn {
      --bs-btn-padding-y: 1.5rem;
      --bs-btn-padding-x: 3.5rem;
    }
  }
}

// Footer
footer{
  .menu-item{
    a{
      color: $white;
      text-decoration: none;
      &:hover{
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }
  }
  .footer-contacts{
    p, div{
      color: $white;
      margin-bottom: 0.5rem;
    }
    a{
      color: $white;
      text-decoration: none;
      .fa-stack{
        .fa-circle{
          color: $white;
        }
        .fa-inverse{
          color: $primary ;
        }
      }
      &:hover{
        font-weight: $font-weight-bold;
        text-decoration: none;
        .fa-stack{
          .fa-circle{
            color: $secondary;
          }
        }
      }
    }
    .contact-info {
      .contact-item {
        display: flex;
        align-items: start;
        margin-bottom: 10px;  /* Adjust as needed */
        i {
          margin-top: .35rem;  /* Adjust as needed */
        }
        span {
          display: block;
        }
      }
    }
  }
}

.contact-page-contacts{
  p, div{
    color: $dark;
    margin-bottom: 0.5rem;
  }
  a{
    color: $white;
    text-decoration: none;
    .fa-stack{
      .fa-circle{
        color: $primary;
      }
      .fa-inverse{
        color: $white ;
      }
    }
    &:hover{
      font-weight: $font-weight-bold;
      text-decoration: none;
      .fa-stack{
        .fa-circle{
          color: $secondary;
        }
      }
    }
  }
  .contact-info {
    .contact-item {
      display: flex;
      align-items: start;
      margin-bottom: 15px;  /* Adjust as needed */
      i {
        margin-top: .35rem;  /* Adjust as needed */
      }
      span {
        display: block;
      }
    }
  }
}


// Animations
.fade-in {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
  &.show {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }
}

.move{
  transition: transform 300ms ease-in-out, opacity 600ms ease-in-out;
  opacity: 0;
  &-right{
    transform: translateX(100%);
  }
  &-left{
    transform: translateX(-100%);
  }
  &-entered {
    transform: translateX(0);
    opacity: 1;
  }
}

.banner{
  // Position headline to center with flexbox
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // Set text attributes
  color: $secondary;
  text-transform: uppercase;
  .text-normal{
    text-transform: none;
  }
  &.internal{
    .h1{
      font-weight: $font-weight-bold;
      font-size: 2.75rem;
      line-height: 1;
      @include media-breakpoint-up(lg) {
        font-size: 3.25rem;
      }
      @include media-breakpoint-up(xl) {
        //font-size: 3.25rem;
      }
    }
    // Set background image sizes
    height: 15rem;
    background-position: center top;
    // Set background height using bootstrap breakpoints variables
    @include media-breakpoint-up(sm) {
      height: 20rem;
    }
    @include media-breakpoint-up(md) {
      height: 22rem;
    }
    @include media-breakpoint-up(lg) {
      height: 25rem;
    }
    @include media-breakpoint-up(xl) {
      height: 23rem;
    }
  }
}

.card {
  position: relative;
  .img-wrapper {
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $tertiary;
      opacity: 0;
      mix-blend-mode: color;
      transition: opacity 0.75s ease;
      z-index: 1;
    }
    .card-img-top {
      display: block;
      filter: grayscale(0);
      transition: filter 0.75s ease;
    }
  }
  &:hover {
    .overlay {
      opacity: 0.9;
      cursor: pointer;
    }
    .card-img-top {
      filter: grayscale(1);
    }
  }
}

.image-container {
  background-size: cover; /* Ensure the image covers the div */
  background-repeat: no-repeat; /* Ensure the image doesn't repeat */
  background-position: center center; /* Center the image both vertically and horizontally */
  height: 350px; /* Set a specific height */
  width: 100%; /* Ensure it takes up the full width of its parent container */
}

.row-layout-5{
  &.row {
    // Three columns to start
    text-align: center;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
    --bs-gutter-y: 1.5rem;
    --bs-gutter-x: 1.5rem;
    .col{
      figure{
        margin-bottom: 0;
      }
    }
    @include row-cols(2);
    // Five columns from medium breakpoint up
    @include media-breakpoint-up(lg) {
      @include row-cols(5);
    }
  }
}

.meet-the-team{
  &.container{
    position: relative;
    max-width: 100%;
    height: 20rem;
    @include media-breakpoint-up(lg) {
      max-width: map-get($container-max-widths, xxl);
      height: 35rem;
    }
    .row{
      align-items: end;
      height: 100%;
        .col{
            text-align: left;
            a {
              &.btn {
                text-decoration: none;
                text-transform: none;
                font-weight: bold;
                color: $white;
                font-size: 3rem;
                text-shadow: 0 0 7px rgba(0, 0, 0, 1);
                &:hover{
                  background: none;
                  border: none;
                }
                border: none;
              }
            }
        }
    }
    &:hover{
      cursor: pointer;
      &:after{
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: $primary;
        //opacity: 0.9;
        //mix-blend-mode: color;
        z-index: 1;
      }
    }
  }
}

.service-icons{
  &.row {
    @include row-cols(1);
    @include media-breakpoint-up(md) {
      @include row-cols(2);
    }
    @include media-breakpoint-up(lg) {
      @include row-cols(4);
    }
  }
  .service-icon {
    position: relative;
    a{
      text-decoration: none;
    }
    .card-title{
      color: $primary;
    }
    .rounded-circle {
      background: $secondary !important;
      width: 10rem;
      height: 10rem;
      margin: 0 auto;
      @include media-breakpoint-up(lg) {
        width: 100% !important;
        height: 100% !important;
      }
      .shp-icon {
        svg {
          width: 100% !important;
          height: 100% !important;
          padding: 1rem;
          .cls-1 {
            fill: $white;
          }
        }
      }
    }
    &:hover {
      .card-title{
        color: var(--bs-primary-mid);
        transition: color 0.5s ease-in-out;
      }
      .rounded-circle {
        background: var(--bs-primary-light) !important;
        transition: background-color 0.5s ease-in-out;
        .shp-icon {
          svg {
            .cls-1 {
              fill: $secondary;
            }
          }
        }
      }
    }
  }
}

.featured-services{
  &.row {
    @include row-cols(1);
    @include media-breakpoint-up(lg) {
      @include row-cols(2);
    }
  }
  .service-icon {

    .card-title{
      color: $primary;
    }
    .shp-icon {
      svg {
        width: 50% !important;
        height: 50% !important;
        @include media-breakpoint-up(lg) {
          width: 100% !important;
          height: 100% !important;
        }
        padding: 3rem;
        .cls-1 {
          fill: $white;
        }
      }
    }
    &:hover {
      .card-title{
        color: var(--bs-primary-mid);
        transition: color 0.5s ease-in-out;
      }
    }
  }
}

// Home Page Banner
.home-page-banner{
  background-size: cover;
  background-repeat: no-repeat;
  height: 20rem;
  @include media-breakpoint-up(lg) {
    height: 35rem;
  }
  // Typing Animation
  .typing-container {
    font-size: 3.5rem;
    line-height: 1;
    color: $secondary;
    text-transform: uppercase;
    transition: opacity 1s;
    font-weight: $font-weight-bold;
    .cursor {
      animation: blink 1s infinite;
    }
    &.fade-out {
      opacity: 0;
    }
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

}

// Icon Rounded Yellow
.icon-yellow{
  background: $secondary;
  border-radius: 50% !important;
  img{
    filter: invert(100%) brightness(200%) contrast(200%);
  }
}


// FAQs
.accordion {
  h3 {
    padding: 20px 0 15px 0;
    font-size:1.3em;
    font-weight:600;
    margin:0;
    letter-spacing:-0.01em;
    cursor: pointer;
    transition: ease all 0.01s;
    position: relative;
    &:hover {
      color: var(--bs-secondary-dark);
      transition: ease all 0.01s;
    }
    &:after{
      content: '\f078';
      position: absolute;
      right: 0;
      font: var(--fa-font-solid);
      color: var(--bs-primary);
    }
    &[aria-expanded="true"]{
      color: var(--bs-primary);
      &:after{
        content: '\f077';
        color: var(--bs-primary-dark);
      }
    }

  }
  .collapse {
    /*		transition: ease all 0.001s;*/
    &.show {
      padding-bottom:10px;
      /*			transition: ease all 0.001s;*/
    }
  }
  .divider {
    background: #fff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height:1px;
    &-grey {
      background: #dfdfdf;
    }
  }
}


// Carousel
// override carousel icons with fontawesome ones...

.carousel-control-prev {
  opacity: 1;
  text-decoration: none;
  :hover {
    color: $secondary;
  }
  .carousel-control-prev-icon {
    background-image: none;
    &:before {
      content: "\f0a8";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 2rem;
    }
  }
}

.carousel-control-next {
  opacity: 1;
  text-decoration: none;
  :hover{
    color: $secondary;
  }
  .carousel-control-next-icon {
    background-image: none;
    &:before {
      content: "\f0a9";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 2rem;
    }
  }
}

.carousel {
  &.slide {
    .fit-container-cover {
      height: 100%;
      width: 100%;
      img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
      }
    }
  }
}

// YouTube Video
.wp-block-embed {
  &.is-type-video {
    &.is-provider-youtube{
      margin-bottom: 0;
      .wp-block-embed__wrapper{
        padding-top: 56.25%;
        position: relative;
        width: 100%;
        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.video-card{
  &.card{
    border: 0;
    --bs-card-bg: var(--bs-primary-light);
    height: 100%;
    .card-header{
      border-bottom: 0;
      padding: 0;
      position: relative;
      cursor: pointer;
      figure{
        margin-bottom: 0;
      }
      .video-play-icon {
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6; /* Initial opacity */
        font-size: 3rem; /* Adjust size as needed */
        color: red; /* Adjust color as needed */
      }
      &:hover{
        .video-play-icon{
          opacity: 1;
          text-shadow: 0 0 4px black; /* horizontal-offset vertical-offset blur-radius color */
        }
      }
    }
    .card-body{
    }
  }
}

.wp-element-caption {
  text-align: center;
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color)!important;
  font-size: 0.75rem;
  margin-top: .75rem;
}



