// Import fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Montserrat&display=swap');

// Override Bootstrap Variables
:root {

  --bs-body-color: #4a4a4a;

  // Override default BS Colors
  --bs-primary: #0e4e96;
  --bs-primary-dark: #2f1240;
  --bs-primary-mid: #86A7CA;
  --bs-primary-light: #e7edf5;
  --bs-secondary: #ffc20e;
  --bs-secondary-dark: #e2a710;
  --bs-secondary-light: #fcf5c5;
  --bs-dark: #343a40;
  --bs-light: #fae6eb;
  --bs-light-dark: #e3cad0;

  // Use RGB Equivalents of the above
  --bs-primary-rgb: 14, 78, 150;
  --bs-primary-dark-rgb: 47, 18, 64;
  --bs-secondary-rgb: 255, 194, 14;
  --bs-secondary-dark-rgb: 216, 175, 47;
  --bs-light-rgb: 250, 230, 235;
  --bs-light-dark-rgb: 227, 202, 208;
  --bs-dark-rgb: 75, 75, 75;

  // Override Spacing
  --bs-nav-link-padding-y: 0;

}

$primary    : #0e4e96;
$secondary  : #ffc20e;
$tertiary   : #5c82b5;
$light      : #e7edf5;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "tertiary": $tertiary,
        "light": $light,
);

// Override fonts
$body-font-family: 'Open Sans', sans-serif;
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-weight: 600;

$enable-negative-margins : true;

$enable-cssgrid: true;

$carousel-control-width: 10%;
